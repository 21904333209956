@import "https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,300;1,400;1,700&family=Source+Code+Pro:wght@300;600;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&family=Roboto:wght@100;300;400;500&display=swap";
.nav-bar.svelte-1pmdsgn {
  width: 100%;
  height: 60px;
  color: #fff;
  background-color: #242424;
  align-items: center;
  padding: 0 5vw;
  display: flex;
  box-shadow: 0 -10px 15px;
}

.nav-banner.svelte-1pmdsgn {
  text-align: center;
  cursor: pointer;
  font-size: 24px;
  display: inline-block;
}

.brand-segment-ctrl.svelte-1pmdsgn {
  font-family: Source Code Pro, monospace;
  font-weight: 600;
}

.brand-segment-plus.svelte-1pmdsgn {
  margin-left: -2px;
  margin-right: -2px;
  font-family: Source Code Pro, monospace;
  font-size: 20px;
  font-weight: 400;
  display: inline-block;
  transform: translateY(-1px);
}

.brand-segment-flow.svelte-1pmdsgn {
  font-family: Source Code Pro, monospace;
  font-weight: 600;
}

.content-area.svelte-uzbink.svelte-uzbink {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  padding: 35px 50px;
}

.section-name.svelte-uzbink.svelte-uzbink {
  margin-left: 10px;
  font-family: Roboto, "sans-serif";
  font-size: 40px;
  font-weight: 400;
}

.btn.svelte-uzbink.svelte-uzbink {
  vertical-align: middle;
  background-color: #fafafa;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  margin-left: 15px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 300;
  box-shadow: 0 2px 10px -8px;
}

.btn.svelte-uzbink.svelte-uzbink:hover {
  cursor: pointer;
}

.section-container.svelte-uzbink.svelte-uzbink {
  width: 100%;
  min-height: 30vh;
  background-color: #fafafa;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  margin-bottom: 30px;
  padding: 0 15px 15px;
  box-shadow: 0 3px 20px -12px;
}

.section-table.svelte-uzbink.svelte-uzbink {
  width: 100%;
  border-collapse: collapse;
}

.section-header.svelte-uzbink.svelte-uzbink {
  text-align: left;
  padding-bottom: 10px;
}

.section-row.svelte-uzbink.svelte-uzbink {
  max-height: 20px;
}

.section-row.svelte-uzbink.svelte-uzbink:hover {
  cursor: pointer;
  background-color: #e5e3e3;
}

.components-section.svelte-uzbink.svelte-uzbink {
  display: flex;
}

.component-col.svelte-uzbink.svelte-uzbink {
  width: 50%;
  background-color: #fafafa;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  padding: 20px;
  box-shadow: 0 3px 20px -12px;
}

.component-col.svelte-uzbink.svelte-uzbink:not(:last-child) {
  margin-right: 15px;
}

.component-col-header.svelte-uzbink.svelte-uzbink {
  font-size: 20px;
  font-weight: 500;
}

.component-col-row.svelte-uzbink.svelte-uzbink {
  margin-top: 10px;
}

.section-row.svelte-uzbink > .svelte-uzbink:first-child, .section-header.svelte-uzbink > .svelte-uzbink:first-child {
  padding-left: 15px;
}

.ref-expression.svelte-60kffc {
  background-color: #fdf6ea;
  border: 1.75px solid #ff9120;
  border-radius: 2px;
  flex-flow: wrap;
  gap: 7px;
  padding: 5px;
  display: flex;
}

input.svelte-1qidylm {
  background-color: #f3f3fb;
  border: 1.75px solid #2626cd;
  border-radius: 2px;
}

input.svelte-1nlw00k {
  background-color: #ffffec;
  border: 1.75px solid #c2c213;
  border-radius: 2px;
}

.empty-expression.svelte-1h74l6v.svelte-1h74l6v {
  min-width: 70px;
  min-height: 24px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 3px;
  display: block;
}

.expression-root.svelte-1h74l6v.svelte-1h74l6v {
  position: relative;
}

.remove-btn.svelte-1h74l6v.svelte-1h74l6v {
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.hovering.svelte-1h74l6v > .remove-btn.svelte-1h74l6v {
  display: block;
}

.decision-expression.svelte-1bbmcy0.svelte-1bbmcy0 {
  background-color: #f3edff;
  border: 1.75px solid #8146ff;
  border-radius: 2px;
  flex-flow: wrap;
  gap: 7px;
  padding: 7px;
  display: flex;
}

.decision-expression.svelte-1bbmcy0 > div.svelte-1bbmcy0 {
  flex-flow: row;
  gap: 7px;
  display: flex;
}

.condition-expression.svelte-tbrw02 {
  background-color: #f3edff;
  border: 1.75px solid #8146ff;
  border-radius: 2px;
  flex-flow: wrap;
  gap: 7px;
  padding: 7px;
  display: flex;
}

.and-or-expression.svelte-1vig6ia {
  background-color: #f3edff;
  border: 1.75px solid #8146ff;
  border-radius: 2px;
  flex-flow: wrap;
  gap: 7px;
  padding: 7px;
  display: flex;
}

.expression-selector.svelte-1czul97.svelte-1czul97 {
  min-width: 400px;
  z-index: 15;
  cursor: default;
  color: #000;
  text-align: initial;
  background-color: #fafafa;
  border: .5px solid #000;
  border-radius: 4px;
  padding: 15px;
  position: absolute;
  top: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 3px 10px -10px;
}

.category.svelte-1czul97.svelte-1czul97 {
  margin-bottom: 20px;
}

.category-header.svelte-1czul97.svelte-1czul97 {
  padding: 5px;
  font-size: 20px;
}

.category-values.svelte-1czul97.svelte-1czul97 {
  flex-flow: wrap;
  display: flex;
}

.category-values.svelte-1czul97 > div.svelte-1czul97 {
  cursor: pointer;
  box-sizing: content-box;
  background-color: #d3d3d3;
  border: 1.2px solid #d3d3d3;
  border-radius: 4px;
  margin-top: 10px;
  margin-right: 10px;
  padding: 3px 10px;
}

.category-values.svelte-1czul97 > div.svelte-1czul97:hover {
  border: 1.2px solid gray;
}

.action-editor.svelte-1bulir1.svelte-1bulir1 {
  min-width: 300px;
  max-width: 500px;
  width: max-content;
  z-index: 15;
  cursor: default;
  color: #000;
  text-align: initial;
  background-color: #fafafa;
  border: .5px solid #000;
  border-radius: 4px;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: calc(100% + 40px);
  transform: translateY(-50%);
  box-shadow: 0 3px 10px -10px;
}

.actions.svelte-1bulir1.svelte-1bulir1 {
  position: absolute;
  top: 5px;
  right: 5px;
}

.section-header.svelte-1bulir1.svelte-1bulir1 {
  font-size: 20px;
  font-weight: 700;
}

.inputs-area.svelte-1bulir1.svelte-1bulir1 {
  display: table;
}

.input.svelte-1bulir1.svelte-1bulir1 {
  display: table-row;
}

.input.svelte-1bulir1 > div.svelte-1bulir1 {
  vertical-align: top;
  padding-top: 5px;
  padding-bottom: 5px;
  display: table-cell;
}

.input-label.svelte-1bulir1.svelte-1bulir1 {
  padding-right: 10px;
  font-weight: 700;
}

select.svelte-1bulir1.svelte-1bulir1 {
  border-radius: 2px;
  padding: 3px 7px;
}

.node.svelte-co8fod {
  color: #fff;
  cursor: pointer;
  filter: brightness(1.1);
  background-color: #000;
  border-radius: 1000px;
  margin: 15px;
  padding: 50px;
  position: relative;
  box-shadow: 0 1px 6px -3px #000;
}

.node-name.svelte-co8fod {
  color: #000;
  white-space: nowrap;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 0 7px;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 1px 7px -4px #000;
}

.svg-path.svelte-co8fod {
  z-index: -10;
  width: 10px;
  position: absolute;
  top: 30px;
  left: 30px;
  overflow: visible;
}

.hovering.svelte-co8fod {
  border: 3px solid #add8e6;
  padding: 47px;
}

.path.svelte-1pdg57u.svelte-1pdg57u {
  text-align: center;
  z-index: 1;
  min-width: 50px;
  background-color: #fafafa;
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  margin: 0 2px;
  padding: 20px 0;
  transition: box-shadow .1s;
  display: flex;
  position: relative;
}

.svg-path.svelte-1pdg57u.svelte-1pdg57u {
  z-index: -10;
  width: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  overflow: visible;
}

.add-node-btn.svelte-1pdg57u.svelte-1pdg57u {
  color: #000;
  width: 60px;
  height: 60px;
  cursor: pointer;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 1000px;
  margin: 15px;
  font-size: 60px;
  font-weight: 400;
  position: relative;
}

.hovering.svelte-1pdg57u.svelte-1pdg57u {
  box-shadow: 0 0 6px -3px #000;
}

.add-step.svelte-1pdg57u.svelte-1pdg57u {
  display: none;
}

.hovering.svelte-1pdg57u > .add-step.svelte-1pdg57u {
  display: block;
}

.add-step-container.svelte-sttwie.svelte-sttwie {
  width: 10px;
  position: relative;
}

.add-step.svelte-sttwie.svelte-sttwie {
  z-index: 5;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.add-step-btns.svelte-sttwie button.svelte-sttwie {
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #000;
  border: none;
  border-radius: 100px;
  font-size: 20px;
  line-height: 10px;
}

.add-step.svelte-sttwie:hover .placeholder.svelte-sttwie, .add-step.svelte-sttwie:not(:hover) .add-step-btns.svelte-sttwie {
  display: none;
}

.placeholder.svelte-sttwie.svelte-sttwie {
  color: #fff;
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 100px;
  padding: 2px;
  font-weight: 700;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.placeholder-text.svelte-sttwie.svelte-sttwie {
  text-align: center;
  line-height: 9px;
}

.fork-route.svelte-sttwie.svelte-sttwie {
  display: inline-block;
  transform: scale(1.1)translate(0, -1px);
}

.action-editor.svelte-a7sun.svelte-a7sun {
  min-width: 300px;
  max-width: 700px;
  width: max-content;
  z-index: 15;
  cursor: default;
  color: #000;
  text-align: initial;
  background-color: #fafafa;
  border: .5px solid #000;
  border-radius: 4px;
  padding: 15px;
  position: absolute;
  bottom: calc(100% + 30px);
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 3px 10px -10px;
}

.actions.svelte-a7sun.svelte-a7sun {
  position: absolute;
  top: 5px;
  right: 5px;
}

.section-header.svelte-a7sun.svelte-a7sun {
  font-size: 20px;
  font-weight: 700;
}

.inputs-area.svelte-a7sun.svelte-a7sun {
  display: table;
}

.input.svelte-a7sun.svelte-a7sun {
  display: table-row;
}

.input.svelte-a7sun > div.svelte-a7sun {
  vertical-align: top;
  padding-top: 5px;
  padding-bottom: 5px;
  display: table-cell;
}

.input-label.svelte-a7sun.svelte-a7sun {
  padding-right: 10px;
  font-weight: 700;
}

.fork.svelte-tyja4a.svelte-tyja4a {
  z-index: 1;
  background-color: #fafafa;
  border-radius: 10px;
  flex-direction: row;
  padding: 28px 0;
  transition: box-shadow .1s;
  display: flex;
  position: relative;
}

.svg-path.svelte-tyja4a.svelte-tyja4a {
  z-index: 1;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  stroke-linecap: square;
  position: absolute;
  top: 0;
  left: 0;
  overflow: visible;
}

.hovering.svelte-tyja4a.svelte-tyja4a {
  box-shadow: 0 0 6px -3px #000;
}

.add-step.svelte-tyja4a.svelte-tyja4a {
  z-index: 5;
  display: none;
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.hovering.svelte-tyja4a > .add-step.svelte-tyja4a {
  display: block;
}

.trigger-set.svelte-j18sjb.svelte-j18sjb {
  border: .5px solid #000;
  border-radius: 10px;
  flex-direction: row;
  margin: 5px;
  padding: 5px;
  display: flex;
  position: relative;
}

.add-trigger.svelte-j18sjb.svelte-j18sjb {
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(50%, -50%);
}

.hovering.svelte-j18sjb.svelte-j18sjb {
  border: 3px solid #add8e6;
  padding: 2.5px;
}

.hovering.svelte-j18sjb > .add-trigger.svelte-j18sjb {
  display: block;
}

.workflow-editor.svelte-1w7nlg6 {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: stretch;
  display: flex;
}

.workflow-view-panel.svelte-1w7nlg6 {
  min-width: 700px;
  flex-grow: 5;
  padding-top: 35px;
}

.workflow-view-container.svelte-1w7nlg6 {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.title-input.svelte-1w7nlg6 {
  width: 700px;
  background-color: #fafafa;
  border: none;
  outline: none;
  font-size: 36px;
  font-weight: 300;
}

.node-flow.svelte-1w7nlg6 {
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  display: flex;
}

.section-headers.svelte-1w7nlg6 {
  margin: 10px;
  font-size: 30px;
  font-weight: 300;
}

.btn.svelte-1w7nlg6 {
  vertical-align: middle;
  background-color: #fafafa;
  border: 1px solid #dcdcdc;
  border-radius: 2px;
  margin-left: 15px;
  padding: 5px 10px;
  font-size: 18px;
  font-weight: 300;
  box-shadow: 0 2px 10px -8px;
}

.btn.svelte-1w7nlg6:hover {
  cursor: pointer;
}

.background.svelte-11cir4s {
  background-color: #fafafa;
  font-family: Roboto, sans-serif;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

/*# sourceMappingURL=index.1290f8ec.css.map */
